import React, { useState } from 'react';
import classNames from 'classnames';
import 'moment/locale/pt-br';
import currency from 'currency.js';

const OfferMeuPostoForm = (props) => {
  const [form, setForm] = useState({ ...props.offer });

  const handleSubmit = (event) => {
    event.preventDefault();
    props.updateOfferState(form);
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setForm({ ...form, [name]: value });
  };

  const translatedKind = () => {
    const { kind } = form;
    const translation = {
      first_price: 'Primeiro Preço',
      normal: 'Cliente Recorrente',
      exclusive: 'Preço Exclusivo',
      vip: 'Preço VIP',
      no_ecash: '-',
      custom_1: 'Especial 1',
      custom_2: 'Especial 2',
      custom_3: 'Especial 3',
      custom_4: 'Especial 4',
      custom_5: 'Especial 5',
      custom_6: 'Especial 6',
    };
    return translation[kind];
  };

  const { debit_ecash, credit_ecash, product_name } = form;
  const { errors, isSubmitting } = props;

  return (
    <div className="offer-form-overlay">
      <form onSubmit={handleSubmit} className="default-form">
        <div className={classNames('last-price')}>
          {product_name} - {translatedKind()}
        </div>

        <div className="input-offer-group">
          <div
            className={classNames('input', 'string', {
              field_with_errors:
                errors.debit_ecash && errors.debit_ecash.length,
            })}
          >
            <label className="string">eCash à vista</label>
            <input
              type="text"
              name="debit_ecash"
              value={debit_ecash}
              onChange={handleChange}
            />
            {errors.debit_ecash && errors.debit_ecash.length ? (
              <div className="offer-form-error-message">
                {errors.debit_ecash}
              </div>
            ) : null}
          </div>

          <div
            className={classNames('input', 'string', {
              field_with_errors:
                errors.credit_ecash && errors.credit_ecash.length,
            })}
          >
            <label className="string">eCash Crédito</label>
            <input
              type="text"
              name="credit_ecash"
              value={credit_ecash}
              onChange={handleChange}
            />
            {errors.credit_ecash && errors.credit_ecash.length ? (
              <div className="offer-form-error-message">
                {errors.credit_ecash}
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-actions">
          <button
            className="blue-btn"
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitting}
          >
            Salvar Oferta
          </button>

          <button
            className="red-btn close-btn"
            type="button"
            onClick={props.closeModal}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default OfferMeuPostoForm;
