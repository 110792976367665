import React, { useState } from 'react';
import classNames from 'classnames';
import OfferEGasosaForm from './OfferEGasosaForm';
import OfferMeuPostoForm from './OfferMeuPostoForm';
import currency from 'currency.js';
import axios from 'axios';

const OfferFormContainer = (props) => {
  const [offer, setOffer] = useState({ ...props.offer });
  const [automatic_offer, setAutomaticOffer] = useState({
    ...props.automatic_offer,
  });
  const [open, setOpen] = useState(props.open || false);
  const [isSubmittingExpire, setIsSubmittingExpire] = useState(false);
  const [editEnabled, setEditEnabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const isEGasosa = () => {
    return props.appId === 'egasosa';
  };

  const toggleFormModal = () => {
    setOpen(!open);
  };

  const renderForm = () => {
    if (!open) return;

    const OfferForm = isEGasosa() ? OfferEGasosaForm : OfferMeuPostoForm;

    return (
      <OfferForm
        updateOfferState={updateOffer}
        closeModal={toggleFormModal}
        offer={offer}
        automaticOffer={automatic_offer}
        isSubmitting={isSubmitting}
        errors={errors}
      />
    );
  };

  const removeOfferWithConfirm = () => {
    const { product_name } = offer;
    if (
      window.confirm(
        `Tem certeza que deseja expirar esta oferta de ${product_name}?`
      )
    ) {
      removeOffer();
    }
  };

  const updateOffer = (form) => {
    setIsSubmitting(true);
    setErrors([]);
    const request = isEGasosa()
      ? {
          state: 'automatic_offer',
          body: 'automatic_offer_price',
          url: props.automaticOfferURL,
        }
      : { state: 'offer', body: 'offer', url: props.offerURL };

    axios
      .post(request.url, { [request.body]: form })
      .then(({ data: json }) => {
        setIsSubmitting(false);
        setOffer(json[request.body]);
        setOpen(false);
      })
      .catch((err) => {
        if (err.response.status === 422 && err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        setIsSubmitting(false);
        console.log('error during offer update', err);
      });
  };

  const removeOffer = () => {
    const request = isEGasosa()
      ? {
          state: {
            automatic_offer: {
              ...offer,
              value_debit: '0,000',
              value_credit: '0,000',
            },
          },
          url: props.automatic_offer.expire_url,
        }
      : {
          state: { offer: { ...offer, price: '0,000', id: null } },
          url: props.expire_url,
        };
    setIsSubmittingExpire(true);
    axios
      .delete(request.url)
      .then(() => {
        setOffer(request.state.offer);
        setIsSubmittingExpire(false);
      })
      .catch((err) => {
        setIsSubmittingExpire(false);
        console.log('error during offer exclusion', err);
      });
  };

  const hasOffer = () => {
    return offer.id !== null;
  };

  const formButtonLabel = () => {
    const { debit_ecash, credit_ecash } = offer;

    const { value_debit, value_credit } = automatic_offer;

    if (isEGasosa() || hasOffer()) {
      var price_value = isEGasosa()
        ? { debit: value_debit, credit: value_credit }
        : { debit: debit_ecash, credit: credit_ecash };

      var prices = [
        currency(price_value.debit, { precision: 3, decimal: ',' }).format(),
      ];

      if (price_value.credit) {
        prices.push(
          currency(price_value.credit, { precision: 3, decimal: ',' }).format()
        );
      }

      return prices.join(' / ');
    } else {
      return '+';
    }
  };

  return (
    <div className="offer-form-container">
      <button
        onClick={toggleFormModal}
        type="button"
        className={classNames('offer-form-value', { hidden: !editEnabled })}
      >
        {formButtonLabel()}
      </button>
      <button
        onClick={removeOfferWithConfirm}
        disabled={isSubmittingExpire}
        type="button"
        className={classNames({
          hidden: !editEnabled || !(isEGasosa() || hasOffer()),
        })}
      >
        X
      </button>
      {renderForm()}
    </div>
  );
};

export default OfferFormContainer;
