import React, { useState } from 'react';
import classNames from 'classnames';
import 'moment/locale/pt-br';
import currency from 'currency.js';

export default function OfferEGasosaForm(props) {
  const [form, setForm] = useState({ ...props.automaticOffer });

  const handleSubmit = (event) => {
    event.preventDefault();
    props.updateOfferState(form);
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setForm({ ...form, [name]: value });
  };

  const translatedKind = () => {
    const { kind } = form;
    const translation = {
      first_price: 'Primeiro Preço',
      normal: 'Cliente Recorrente',
      exclusive: 'Preço Exclusivo',
      vip: 'Preço VIP',
      no_ecash: '-',
      custom_1: 'Especial 1',
      custom_2: 'Especial 2',
      custom_3: 'Especial 3',
      custom_4: 'Especial 4',
      custom_5: 'Especial 5',
      custom_6: 'Especial 6',
    };
    return translation[kind];
  };

  return (
    <div className="offer-form-overlay">
      <form onSubmit={handleSubmit} className="default-form">
        <div className={classNames('last-price')}>
          {form.product_name} - {translatedKind()}
        </div>
        <div className="input-offer-group">
          <div
            className={classNames('input', 'string', {
              field_with_errors:
                props.errors.value_debit && props.errors.value_debit.length,
            })}
          >
            <label className="string">Cashback à vista</label>
            <input
              type="text"
              name="value_debit"
              value={form.value_debit}
              onChange={handleChange}
            />
            {props.errors.value_debit && props.errors.value_debit.length ? (
              <div className="offer-form-error-message">
                {props.errors.value_debit}
              </div>
            ) : null}
          </div>

          <div
            className={classNames('input', 'string', {
              field_with_errors:
                props.errors.value_credit && props.errors.value_credit.length,
            })}
          >
            <label className="string">Cashback Crédito</label>
            <input
              type="text"
              name="value_credit"
              value={form.value_credit}
              onChange={handleChange}
            />
            {props.errors.value_credit && props.errors.value_credit.length ? (
              <div className="offer-form-error-message">
                {props.errors.value_credit}
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-actions">
          <button
            className="blue-btn"
            type="submit"
            disabled={props.isSubmitting}
          >
            Salvar Oferta
          </button>

          <button
            className="red-btn close-btn"
            type="button"
            onClick={props.closeModal}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
